import { template as template_8d7c834ab4044e9d8212e47bd5d0bcd1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_8d7c834ab4044e9d8212e47bd5d0bcd1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
