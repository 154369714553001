import { template as template_470bce410fa741d8af242ed35ca25499 } from "@ember/template-compiler";
const SidebarSectionMessage = template_470bce410fa741d8af242ed35ca25499(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
