import { template as template_0ff79ca2a75141898768e5933977b1d7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0ff79ca2a75141898768e5933977b1d7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
