import { template as template_fe8a41f384e743948848c5672427fec2 } from "@ember/template-compiler";
const FKLabel = template_fe8a41f384e743948848c5672427fec2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
